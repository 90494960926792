import Vue from 'vue'
import App from './App.vue'

// EventBus
export const EventBus = new Vue();

// Plugins
import Store from './plugins/Store'
import Router from './plugins/Router'
import i18n from './plugins/i18n'
import Ckeditor from './plugins/Ckeditor'
import './plugins/Filters'
import './plugins/Directives'
import './plugins/Vee-Validate'
import './plugins/Vue-Phone-Number-Input'
import GlobalRegistration from '@/plugins/GlobalRegistration'

// Import
import VueMeta from 'vue-meta'
import VueAutosuggest from 'vue-autosuggest';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSidebarMenu from 'vue-sidebar-menu'
import VueTheMask from 'vue-the-mask'
import VCalendar from 'v-calendar'
import Multiselect from '/src/libs/vue-multiselect.min'
import Toast from 'vue-toastification';
import VueSweetalert2 from 'vue-sweetalert2';

import '@/assets/css/goodtable/goodtable.css';
import VueNestable from 'vue-nestable';

// Style
import './assets/css/components/sweetalert.css';
import 'remixicon/fonts/remixicon.css'
import 'nprogress/nprogress.css';
import './assets/css/style.scss'
import './assets/css/extra.css'

import moment from 'moment';

// ???
Vue.config.productionTip = false;

// Vue Good Table
import VueGoodTablePlugin from 'vue-good-table';
Vue.use(VueGoodTablePlugin);

import cloneButton from "@/components/elements/CloneButton.vue"

Vue.component('b-button', cloneButton)

// Using
Vue.use(VueTheMask)
Vue.use(Router)
Vue.use(VueSidebarMenu)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueAutosuggest)
Vue.use(VCalendar, { componentPrefix: 'v' })
Vue.use(VueMeta)
Vue.use(Toast, { maxToasts: 2000 });

// Sweet Alert
const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ffffff',
    icon: 'question',
}
Vue.use(VueSweetalert2, options);

// Nestable
Vue.use(VueNestable);

GlobalRegistration({ Vue })

// Html To Paper
import VueHtmlToPaper from 'vue-html-to-paper';
const vueHtmlToPaperOptions = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    timeout: 1000,
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'http://localhost:3000/print.scss'
    ],
    autoClose: true,
    windowTitle: window.document.title
}
Vue.use(VueHtmlToPaper, vueHtmlToPaperOptions);
// Helpers
import downloadFile, { downloadExcelFile, pdfPrint, createExcelWorkbookFromJson } from '@/helpers/downloadFile'



import getButtonColors from '@/helpers/getButtonColors'
import getDayText from '@/helpers/getDayText'
import getYesNoText from '@/helpers/getYesNoText'
import nl2br from '@/helpers/nl2br'
import openInNewWindow from '@/helpers/openInNewWindow'
import showErrors from '@/helpers/showErrors'
import { trim } from 'core-js/internals/string-trim'
import CountryService from "@/services/CountryService";
import qs from "qs";

Vue.mixin({
    methods: {
        getButtonColors,
        getDayText,
        getYesNoText,
        nl2br,
        openInNewWindow,
        checkPermission(permission) {
            const user = this.$store.getters["auth/getUser"];
            if (!Array.isArray(user.permissions)) {
                return false;
            }
            return user.permissions.includes(permission);
        },
        getLocaleText: function (row, col, $return = "") {
            if (
                row[col]
            ) {
                return row[col];
            }
            return $return;
        },
        getLocaleField: function (col) {
            return col;
        },
        getStatusText: function (status) {
            if (status === "a") {
                return "Active";
            } else if (status === "p") {
                return "Passive";
            }
        },
        getSemester: function (number) {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return this.$t("fall");
                case 2:
                    return this.$t("spring");
                case 3:
                    return this.$t("summer");
                case 4:
                    return this.$t("yearly");
                default:
                    return number;
            }
        },
        getSectionType: function (number) {
            number = parseInt(number);
            switch (number) {
                case 1:
                    return this.$t("theoric");
                case 2:
                    return this.$t("practical");
                default:
                    return number;
            }
        },
        getPlacementType: function (type) {
            switch (type) {
                case "placement":
                    return this.$t("placement_exam");
                case "proficiency":
                    return this.$t("proficiency_exam");
                default:
                    return "";
            }
        },
        getPlacementExamType: function (type) {
            switch (type) {
                case "in_class":
                    return this.$t("in_class");
                case "online":
                    return this.$t("online");
                case "hybrid":
                    return this.$t("hybrid");
                default:
                    return "";
            }
        },
        translate(text) {
            return this.$t(text);
        },
        toUpperCase(text) {
            return this.$t(text).toUpperCase();
        },
        confirmModal(msg, callback) {
            this.$store.commit("setConfirmModal", { msg, callback });
        },
        deleteModal(callback) {
            this.$store.commit("setDeleteModal", { callback });
        },
        groupBy(arr, criteria) {
            const newObj = arr.reduce(function (acc, currentValue) {
                if (!acc[currentValue[criteria]]) {
                    acc[currentValue[criteria]] = [];
                }
                acc[currentValue[criteria]].push(currentValue);
                return acc;
            }, {});
            return newObj;
        },
        getOptionText(options, value) {
            if (!value) {
                return "";
            }
            var found = "";
            options.forEach((item) => {
                if (item.value === value) {
                    found = item.text;
                }
            });
            return found;
        },
        getOptionItem(options, value) {
            var found = null;
            options.forEach((item) => {
                if (item.value === value) {
                    found = item;
                }
            });
            return found;
        },
        getParameterText(options, value) {
            var found = "";
            options.forEach((item) => {
                if (item.code === value) {
                    found = item.name;
                }
            });
            return found;
        },
        br2space(str) {
            return str.replace(/<br\s*\/?>/gm, " ");
        },
        isNull(value) {
            return typeof value != "undefined" || value !== null || value != ""
                ? true
                : false;
        },
        isNotNull(value) {
            return typeof value != "undefined" && value !== null && value != ""
                ? true
                : false;
        },
        isNotNullValue(value, nullValue = "-") {
            return typeof value != "undefined" && value !== null && value != ""
                ? value
                : nullValue;
        },
        getObjectValue(object, value, nullValue = "-") {
            if (
                object &&
                typeof object[value] != "undefined" &&
                object[value] != null &&
                trim(object[value]) != ""
            ) {
                return object[value];
            }
            return nullValue;
        },
        getDate(format = "MM/DD/YYYY") {
            moment.locale("en");
            return moment().format(format);
        },
        toLocaleDate(date, format = "") {
            if (!date) {
                return "";
            }
            moment.locale("en");
            if (format) {
                return moment(date, "MM/DD/YYYY").format(format);
            }
            return moment(date, "MM/DD/YYYY").format("MM/DD/YYYY");
        },
        toLocaleDateTime(date) {
            return moment(date, "MM/DD/YYYY").format("MM/DD/YYYY HH:mm");
        },
        formatNumber(value) {
            return this.$options.filters.formatNumber(value);
        },
        changeText(text) {
            return text.replaceAll('i', 'ı').toUpper()
        },
        formatDate(value) {
            return this.$options.filters.dateFormat(value);
        },
        spaceToBR(text) {
            return text.replaceAll(" ", "<br>");
        },
        toast(data) {
            if (data.success) {
                this.$toast.success(this.$t("api." + data.message));
            } else {
                this.$toast.error(this.$t("api." + data.message));
            }
        },
        _downloadFile: downloadFile,
        _downloadExcelFile: downloadExcelFile,
        _pdfPrint: pdfPrint,
        _openFile(response) {
            const blob = new Blob([response.data], {
                type: response.headers["content-type"],
            });
            const objectUrl = window.URL.createObjectURL(blob);
            window.open(objectUrl);
        },
        _openBinaryPdfInNewTab(response) {
            let binaryData = [];
            binaryData.push(response.data);
            let fileURL = window.URL.createObjectURL(
                new Blob(binaryData, { type: "application/pdf" })
            );

            window.open(fileURL);
        },
        setBooleans(form) {
            Object.keys(form).forEach((key) => {
                let val = form[key];
                if (val === true) {
                    val = 1;
                } else if (val === false) {
                    val = 0;
                }
                form[key] = val;
            });
        },
        objectToArrayObject(data) {
            let response = [];
            if (Object.keys(data)) {
                Object.keys(data).forEach((key) => {
                    response.push({
                        key: key,
                        ...data[key],
                    });
                });
            }
            return response;
        },
        showErrors,
        getInfoMessage(data) {
            try {
                let msg = this.$t("api." + data.info);
                if (
                    typeof data.info_params != "undefined" &&
                    data.info_params
                ) {
                    Object.keys(data.info_params).forEach((key) => {
                        msg = msg.replaceAll("#" + key, data.info_params[key]);
                    });
                }

                return msg;
            } catch (e) { }
        },
        getDaysObject() {
            return [
                { value: null, text: i18n.t("select") },
                { value: 1, text: i18n.t("monday") },
                { value: 2, text: i18n.t("tuesday") },
                { value: 3, text: i18n.t("wednesday") },
                { value: 4, text: i18n.t("thursday") },
                { value: 5, text: i18n.t("friday") },
                { value: 6, text: i18n.t("saturday") },
                { value: 7, text: i18n.t("sunday") },
            ];
        },
        setWithExpiry(key, value, ttl = 3600000) {
            const now = new Date();

            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const item = {
                value: value,
                expiry: now.getTime() + ttl,
            };
            localStorage.setItem(key, JSON.stringify(item));
        },
        getWithExpiry(key) {
            const itemStr = localStorage.getItem(key);
            // if the item doesn't exist, return null
            if (!itemStr) {
                return null;
            }
            const item = JSON.parse(itemStr);
            const now = new Date();
            // compare the expiry time of the item with the current time
            if (now.getTime() > item.expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                localStorage.removeItem(key);
                return null;
            }
            return item.value;
        },
        base64Encode(data) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
    },
});

String.prototype.toUpper = function () {
    return this.toUpperCase();
}

Vue.component('multiselect', Multiselect)
new Vue({
    render: h => h(App),
    i18n,
    router: Router,
    store: Store
}).$mount('#app')

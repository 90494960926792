const routes = [
    {
        path: "/course/registration",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    },
    {
        path: "/course/registration/:id",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true
        },
    }
];

export default routes;

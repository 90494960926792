import ExcelJS from 'exceljs';
import i18n from '@/plugins/i18n'
import Vue from 'vue';

export default function _downloadFile(response, fileName) {
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const data = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(function () {
        window.URL.revokeObjectURL(data);
    }, 100);
}


export function pdfPrint(fileData, fileType) {
    return new Promise((resolve, reject) => {
        try {
            var blob = new Blob([fileData], { type: fileType });// change resultByte to bytes
            var blobURL = URL.createObjectURL(blob);

            const printFrame = document.querySelector("#print-frame")
            if (printFrame) {
                printFrame.src = blobURL;
                printFrame.onload = function () {
                    setTimeout(function () {
                        printFrame.focus();
                        printFrame.contentWindow.print();
                        return resolve(true)
                    }, 1);
                };
            } else {
                return resolve(false)
            }
        } catch (err) {
            return reject(false)
        }
    })
}

export async function downloadExcelFile({ data, filename, headers = [], formatting = {} }) {
    if (!data?.length) {
        Vue.$toast.error(i18n.t('no_result_2'))
        return;
    }
    const workbook = await createExcelWorkbookFromJson({ data, headers, formatting });

    const buffer = await workbook.xlsx.writeBuffer();

    _downloadFile({
        data: buffer,
        headers: {
            'content-type': 'application/json'
        }
    }, filename)
}

export async function createExcelWorkbookFromJson({ data, headers = [], formatting = {} }) {
    const columns = data[0];

    // Object.keys(columns).forEach((key, index) => headers[index].key = key)

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(i18n.t('page'), { pageSetup: { fitToPage: true, horizontalCentered: true } });

    worksheet.columns = headers
    data.forEach(item => {
        worksheet.addRow(item);
    });

    if ('id' in columns) {
        worksheet.getColumn("id").hidden = true;
    }

    for (const property in formatting) {
        const formatToRow = worksheet.getColumn(property)
        formatToRow.eachCell({ includeEmpty: true }, function (cell, rowNumber) {
            if (rowNumber > 1) {
                cell.value = formatting[property](cell.value)
            }
        });
    }

    return workbook;
}

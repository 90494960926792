import Vue from 'vue'
import moment from 'moment'
import i18n from "@/plugins/i18n"

// Time Ago
Vue.filter('timeAgo', (value, locale = 'en') => {
    const lang = {
        minute: 'Minute',
        hour: 'Hour',
        day: 'Day',
    };

    let now = Math.round((Date.now() / 1000));
    let difference = now - value;

    if (difference < 3600) {
        let minute = Math.round(difference / 60);
        if (minute == 0) minute = 1;
        return minute + ' ' + lang.minute;
    }
    else if (difference < 86400) {
        let hour = Math.round(difference / 3600);
        return hour + ' ' + lang.hour;
    }
    else {
        let day = Math.round(difference / 86400);
        return day + ' ' + lang.day;
    }
});

// Time Format
Vue.filter('timeFormat', (value, format = 'HH:mm') => {
    if (!value) return ''
    else return moment(value * 1000).format(format);
});

// Date Format
Vue.filter('dateTimeFormat', (value) => {
    if (!value) return ''
    else {
        if (moment(value * 1000).format('DD/MM/YY') == moment().format('DD/MM/YY')) {
            return moment(value * 1000).format('HH:mm');
        }
        else {
            return moment(value * 1000).format('YY MM DD HH:mm');
        }
    }
});

Vue.filter('dateFormat', (value) => {
    if (!value) return ''
    else {
        return moment(value).format('MM/DD/YYYY');
    }
});


// Uppercase
Vue.filter('toUpperCase', (value) => {
    return value.toUpperCase();
});

Vue.filter('formatToCurrency',(number, currency)=>{
    return new Intl.NumberFormat('en-US',  {style:'currency', currency: currency,  minimumFractionDigits: 2}).format(number);
});

Vue.filter('formatNumber',(number)=>{
    return new Intl.NumberFormat('en-US',  {minimumFractionDigits: 2}).format(number);
});

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// import trApp from '@/locales/tr/app';
// import trValidation from '@/locales/tr/validation';
// import trTexts from '@/locales/tr/texts';
// import trRoles from '@/locales/tr/role';

import enApp from '@/locales/en/app';
import enValidation from '@/locales/en/validation';
import enTexts from '@/locales/en/texts';
import enRoles from '@/locales/en/role';

export default new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    silentTranslationWarn: true,
    messages: {
        en: {
            ...enApp,
            validation: enValidation,
            ...enTexts,
            ...enRoles,
        },
    },
});

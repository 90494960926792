const routes = [
    {
        path: "/supervisor/approvals",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "studentsemester_index"
            //yetkisi bu advisorapprovals_index suan yetki  için idareten olan yetkıyı kullanıyoruz düzenlenecek
        }
    }
];

export default routes;

<template>
  <div v-bind="$attrs">
    <h6 v-if="isTitle">{{ $t('filter1') }}</h6>
    <div class="border rounded-sm p-3 mb-3 p-lg-5  datatable-filter" v-on:keyup.enter="onEnter" v-if="isSmallFilter">
      <div class="d-flex flex-column flex-xl-row">
        <div class="flex-grow-1 mr-4">
          <slot />
        </div>
        <div class="d-flex">
          <b-button variant="primary" class="width-40 mr-1" @click="$emit('filter')">
            <i class="ri-search-line"></i>
          </b-button>
          <b-button variant="danger" class="width-40" @click="$emit('filterClear')">
            <i class="ri-close-line"></i>
          </b-button>
        </div>
      </div>
    </div>

    <div class="border rounded-sm p-1 p-lg-5 mb-3 datatable-filter" v-on:keyup.enter="onEnter" v-else>
      <div class="d-flex flex-column flex-xl-row">
        <div class="flex-grow-1 mr-lg-4">
          <slot />
        </div>
        <div class="width-lg-110">
          <div class="d-flex d-xl-block" :class="filterButtonClass">
            <div class="mb-2 mr-2 mr-xl-0" v-if="filterButton">
              <b-button variant="primary" block @click="$emit('filter')">
                {{ $t('search').toUpper() }}
              </b-button>
            </div>
            <div class="mb-2 mr-2 mr-xl-0" v-if="exportExcel">
              <b-button variant="success" block @click="$emit('exportExcel')">
                {{ 'EXCEL' }}
              </b-button>
            </div>
            <div>
              <json-excel :data="jsonExcelData" :fields="jsonExcelFields" type="xls"
                          v-if="jsonExcelData && jsonExcelFields && jsonExcelButton">
                <b-button variant="success" block class="py-3 mb-2 " style="line-height: 15px;"
                          v-if="jsonExcelButton">
                  {{ $t('excel') }}
                </b-button>
              </json-excel>
            </div>
            <slot name="json-excel" />
            <div class="mb-2 mr-2 mr-xl-0" v-if="studentListReport">
              <b-button variant="success" block class="py-5" style="line-height: 15px;"
                        @click="$emit('getStudentListReport')">
                {{ $t('student_list_report') }}
              </b-button>
            </div>
            <div class="mb-2 mr-2 mr-xl-0" v-if="exportPrint">
              <b-button variant="secondary" block @click="$emit('exportPrint')">
                {{ 'PRINT' }}
              </b-button>
            </div>
            <div>
              <b-button class="mb-2" variant="danger" block @click="$emit('filterClear')">
                {{ $t('clear').toUpper() }}
              </b-button>
            </div>

            <div v-if="otherButton.show">
              <b-button variant="outline-secondary secondary-border-light" block class="mr-2"
                        v-show="otherButton.show" @click="otherButton.emit ? $emit(otherButton.emit) : ''">
                                <span class="d-flex">
                                    <i :class="otherButton.icon ? otherButton.icon + ' mr-1' : ''"
                                       v-if="otherButton.icon"></i>
                                    <span v-if="otherButton.title">{{ otherButton.title }}</span>
                                </span>
              </b-button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JsonExcel from "vue-json-excel";
export default {
  components: {
    JsonExcel
  },
  props: {
    filterButtonType: {
      type: String,
      default: 'row'
    },
    filterButtonClass: {
      default: 'mt-1 mt-md-5'
    },
    exportExcel: {
      type: Boolean,
      default: false
    },
    exportPrint: {
      type: Boolean,
      default: false
    },
    jsonExcelButton: {
      type: Boolean,
      default: false
    },
    jsonExcelData: {
      type: Array,
    },
    jsonExcelFields: {
      type: Object
    },
    isTitle: {
      type: Boolean,
      default: true
    },
    filterButton: {
      type: Boolean,
      default: true
    },
    isSmallFilter: {
      type: Boolean,
      default: false
    },
    studentListReport: {
      type: Boolean,
      default: false
    },
    isKeyUp:{
      type:Boolean,
      default:true
    },
    otherButton: {
      type: Object,
      default: () => ({
        show: false,
        icon: null,
        title: null,
        emit: null
      })
    },
  },
  watch: {
    jsonExcelData(newValue) {
      this.jsonExcelData = newValue

    },
    jsonExcelFields(newValue) {
      this.jsonExcelFields = newValue

    }


  },
  methods: {
    onEnter: function (e) {
      if (!this.isKeyUp){
        return
      }
      this.$emit('filter', e)

    }
  }
}
</script>

<style>
@media (min-width: 991px) {
  .width-lg-110 {
    width: 110px;
  }
}
</style>

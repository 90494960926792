const routes = [
    {
        path: "/document/request/form",
        component: () => import("../pages/student/DocumentRequestForm"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    },
    {
        path: "/document/requests",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: 'documentrequest_index',
        }
    },
    {
        path: "/document/requests/ebys/diploma",
        component: () => import("../pages/DiplomaEbysSend"),
        meta: {
            isAuthenticated: true,
            //permission: 'documentrequest_diploma',
        }
    }
]

export default routes;

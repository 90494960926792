const routes = [
    {
        path: "/advisor/students/report",
        component: () => import("../pages/Report"),
        meta: {
            isAuthenticated: true,
            permission: "electivepool_report"
        }
    }

];

export default routes;

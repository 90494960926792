const routes = [
    {
        path: "/course/approval/check",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "slot_index"
        }
    }
];

export default routes;

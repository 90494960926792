const routes = [
    {
        path: "/initial/complaint-form",
        component: () => import("../pages/index"),
        meta: {
            isAuthenticated: true,
            permission: "courseregistration_enrollmentagreement"
        }
    }
];

export default routes;
 
const routes = [
    {
        path: "/student/reports",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            isStaff:true
        }
    }
];

export default routes;

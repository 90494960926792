const routes = [
    {
        path: "/student/application/form",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
           // permission: ""
        }
    }
];

export default routes;

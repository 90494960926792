const routes = [
    {
        path: "/work/order-request-form",
        component: () => import("../pages/index"),
        meta: {
            isAuthenticated: true,
            permission: "courseregistration_enrollmentagreement",
        },
    },
];

export default routes;

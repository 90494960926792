<template>
    <div id="print-content">
        <slot name="header"/>
        <SidebarMenu/>
        <div class="main-content">
            <slot name="header-mobile"></slot>
            <slot></slot>
            <template v-if="!onLine">
                check internet
            </template>
        </div>
        <div class="d-flex flex-column vh-100" v-if="hasFooter">
            <Footer/>
        </div>
        <DeleteModal/>
        <ConfirmModal/>
        <div id="pdfViewer">
            <b-modal id="pdfViewerModal" hide-footer
                     size="xl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="pdfViewerModalClose()"
            >
                <template #modal-title>
                </template>
                <vue-pdf-app :pdf="pdfFileData"
                             :fileName="pdfFileName"
                             :config="pdfFileConfig"
                             :page-scale="'page-actual'"
                             v-if="pdfFileShow"
                />
                <div class="row" v-if="actionButton">
                    <div class="col-12 d-flex mt-4">
                        <b-button @click="actionFunc" variant="primary" class="mr-2">{{ actionButtonLabel }}</b-button>
                        <b-button @click="pdfViewerModalClose">{{ $t('cancel') }}</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
        <div id="htmlViewer">
            <b-modal id="htmlViewerModal" hide-footer
                     size="xl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="htmlViewerModalClose()"
            >
                <template #modal-title>
                </template>
                <div v-html="dataHtml"/>
            </b-modal>
        </div>
        <div id="detailErrors">
            <b-modal id="detailErrorsModal"
                     hide-footer
                     centered
                     size="lg"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="detailErrorsModalClose()"
            >
                <template #modal-title>
                    {{ $t('error') }}
                </template>
                <div>
                    <h5 v-if="detailErrors && detailErrors.title">
                        {{ detailErrors.title }}
                    </h5>
                    <div v-if="detailErrors && detailErrors.errors" style="max-height: 400px; overflow-y: auto">
                        <ol>
                            <li v-for="(error,i) in detailErrors.errors" :key="i">
                                <div v-for="(key,index) in getKeys(error.data)" :key="index">
                                    <span v-html="$t(key,{ 'interpolation': {'escapeValue': false} })"></span>
                                    :{{error.data ? error.data[key]:''}}
                                </div>
                                - {{ error.message }}
                            </li>
                        </ol>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import SidebarMenu from '@/layouts/AppLayout/SidebarMenu'
import Footer from '@/layouts/AppLayout/Footer.vue'

// Pdf
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';

// Modal
import DeleteModal from '@/components/elements/DeleteModal';
import ConfirmModal from '@/components/elements/ConfirmModal';

// Event Bus
import {EventBus} from '@/main'

export default {
    name: 'AppLayout',
    components: {

        SidebarMenu,
        Footer,
        VuePdfApp,

        DeleteModal,
        ConfirmModal
    },
    data() {
        return {
            onLine: navigator.onLine,
            showBackOnline: false,

            pdfFileShow: false,
            pdfFileData: null,
            pdfFileName: null,
            pdfFileConfig: {
                sidebar: {
                    viewThumbnail: true,
                    viewOutline: true,
                    viewAttachments: true
                },
                secondaryToolbar: {
                    secondaryPresentationMode: false,
                    secondaryOpenFile: false,
                    secondaryPrint: false,
                    secondaryDownload: true,
                    secondaryViewBookmark: true,
                    firstPage: true,
                    lastPage: true,
                    pageRotateCw: true,
                    pageRotateCcw: true,
                    cursorSelectTool: false,
                    cursorHandTool: false,
                    scrollVertical: false,
                    scrollHorizontal: false,
                    scrollWrapped: false,
                    spreadNone: false,
                    spreadOdd: false,
                    spreadEven: false,
                    documentProperties: false
                },
                toolbar: {
                    toolbarViewerLeft: {
                        findbar: true,
                        previous: true,
                        next: true,
                        pageNumber: true
                    },
                    toolbarViewerRight: {
                        presentationMode: false,
                        openFile: false,
                        print: false,
                        download: true,
                        viewBookmark: false
                    },
                    toolbarViewerMiddle: {
                        zoomOut: true,
                        zoomIn: true,
                        scaleSelectContainer: true
                    }
                },
                errorWrapper: true

            },

            dataHtml: '',
            actionButton: false,
            actionButtonLabel: null,
            actionFunc: function () {
                return false;
            },

            detailErrors: {}
        }
    },
    computed: {
        hasFooter() {
            return this.$route.meta.hasFooter;
        }
    },
    watch: {
        onLine(v) {
            if (v) {
                this.showBackOnline = true;
                setTimeout(() => {
                    this.showBackOnline = false;
                }, 1000);
            }
        }
    },
    methods: {
        updateOnlineStatus(e) {
            const {type} = e;
            this.onLine = type === 'online';
        },

        // Pdf Viewer
        pdfViewerModalShow(event) {
            if (event.hasOwnProperty('config')) {
                this.actionButton = event.config.actionButton
                this.actionButtonLabel = event.config.actionButtonLabel
                this.actionFunc = event.config.actionFunc
            } else {
                this.actionButton = false
                this.actionButtonLabel = ''
                this.actionFunc = () => {
                    return false;
                }
            }
            this.pdfFileData = event.pdfFileData
            this.pdfFileName = event.pdfFileName
            this.note = event.note
            this.pdfFileShow = true;
            this.$bvModal.show('pdfViewerModal')
        },
        pdfViewerModalClose() {
            this.$bvModal.hide('pdfViewerModal')
            this.pdfFileShow = false;
            this.pdfFileData = null;
            this.pdfFileName = null;
        },

        // Html Viewer
        htmlViewerModalShow(event) {
            this.$bvModal.show('htmlViewerModal')
            this.dataHtml = event;
        },
        htmlViewerModalClose() {
            this.dataHtml = '';
            this.$bvModal.hide('htmlViewerModal')
        },

        // Detail Errors
        detailErrorsModalShow(event) {
            this.detailErrors = event;
            this.$bvModal.show('detailErrorsModal')
        },
        detailErrorsModalClose() {
            this.detailErrors = {}
            this.$bvModal.hide('detailErrorsModal')
        },
        getKeys(data={}){
            return Object.keys(data)
        }
    },
    beforeCreate() {
        this.$store.dispatch('auth/initAppPage');
    },
    mounted() {
        EventBus.$on('pdfViewerModalShow', (event) => {
            this.pdfViewerModalShow(event)
        });
        EventBus.$on('htmlViewerModalShow', (event) => {
            this.htmlViewerModalShow(event)
        })
        EventBus.$on('detailErrorsModalShow', (event) => {
            this.detailErrorsModalShow(event)
        })

        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    }
}
</script>



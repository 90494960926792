import Vue from 'vue';
import i18n from '@/plugins/i18n'

export default function showErrors(e, form = null, isArrayBuffered = false,hideInvalidMessage=false) {
    let data = e.data;
    if (isArrayBuffered) {
        var decoder = new TextDecoder('utf-8')
        data = JSON.parse(decoder.decode(new Uint8Array(e.data)))
    }

    // 406
    if (e.status == '406') {
        let msg = getErrorMessage(data);
        Vue.$toast.error(msg);
        return msg;
    }

    // 422
    else if (e.status == '422') {
        if (form == null) {
            form = this.$refs.form;
        }
        for (const [key, value] of Object.entries(data.errors)) {
            if (form.errors[key]) {
                form.errors[key].push(value[0]);
            }
        }
        if(hideInvalidMessage){
            return
        }
        if (typeof data.message != 'undefined') {
            let msg = i18n.t('api.' + data.message)
            if (e.config.method == 'get' || e.config.method == 'delete') {
                for (const [key, value] of Object.entries(data.errors)) {
                    msg += '\n' + value[0];
                }
            }
            Vue.$toast.error(msg);
            return msg;
        }
    }
}

export function getErrorMessage(data) {
    if (typeof data.message != 'undefined') {
        let msg = i18n.t('api.' + data.message);
        msg = msg.replaceAll('api.', '');
        if (typeof msg == 'undefined') {
            msg = data.message;
        }
        if (data.params != 'undefined' && data.params) {
            if (msg == 'API_MESSAGE' && data.params['message']) {
                return data.params['message'];
            }
            Object.keys(data.params).forEach(key => {
                if (key.includes('#' + key)) {
                    msg = msg.replaceAll('#' + key, data.params[key]);
                }
            })
        }
        return msg;
    }
    else {
        return i18n.t('something_went_wrong');
    }
}

import Base from '@/plugins/Base';
const module = {
    namespaced: true,
    state: () => ({
        formData: {},
    }),
    getters: {
        formData(state) {
            return state.formData;
        },
    },
    mutations: {
        setFormData(state, formData) {
            Base.LocalStorage.set('formData', formData);
            state.formData = formData;
        },
        clearFormData(state) {
            Base.LocalStorage.remove('formData');
            state.formData = {};
        },
    },
    actions: {
        saveFormData({ commit }, formData) {
            commit('setFormData', formData);
        },
        deleteFormData({ commit }) {
            commit('clearFormData');
        },
    },
};


export default module;
